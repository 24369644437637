import React, { useState } from "react";
import "./style.scss";
import { ReactComponent as Insta } from "@samedaycustom/assets/images/LandingImages/insta.svg";
import { ReactComponent as Face } from "@samedaycustom/assets/images/LandingImages/facebook.svg";
import { HOMEPAGE, FAQ, CONTACT, SIGN_UP_PAGE, TCS } from "vendor/routes/screen";
import { ReactComponent as Logo } from "@samedaycustom/assets/images/logo-full.svg";
import { NavLink, useHistory } from "react-router-dom";
import { AuthedButton } from "@samedaycustom/core-ui";
import { ReactComponent as Menu } from "@samedaycustom/assets/images/LandingImages/menu.svg";
import { ReactComponent as Close } from "@samedaycustom/assets/images/LandingImages/close.svg";

const TCSpage = () => {
  const history = useHistory();
  const getStartedFunc = () => {
    history.push(SIGN_UP_PAGE);
  };
  const [openMenu, setOpen] = useState(false);
  return (
    <div className="TSCPage">
      <div className="Landing__Navigation">
        <NavLink to={HOMEPAGE}>
          <Logo />
        </NavLink>
        <div className="Landing__Navigation__grp">
          <div className="linksGroup">
            <a href="/#howitworks">
              <span>How it works</span>
            </a>
            <NavLink to={FAQ} activeClassName="Landing__activeLink">
              <span> FAQ </span>
            </NavLink>
            <NavLink to={CONTACT} activeClassName="Landing__activeLink">
              <span>Get in touch</span>
            </NavLink>
          </div>
          <div className="actionGroup">
            <NavLink to="/signin">
              <span className="lgin"> Log In </span>
            </NavLink>
            <AuthedButton onClick={getStartedFunc} className="Btn--Primary actionGroupBtn">
              <span>Get Started</span>
            </AuthedButton>
          </div>
        </div>
        <div className="Landing__Navigation__menu" onClick={() => setOpen(true)}>
          <Menu />
        </div>
        <div className={`Landing_mobileMenu ${openMenu ? "openMobileMenu" : ""}`}>
          <div className="mobileMenuNav">
            <NavLink to={HOMEPAGE}>
              <Logo />
            </NavLink>
            <div className="Landing__Navigation__menu" onClick={() => setOpen(false)}>
              <Close />
            </div>
          </div>
          <div className="mobileMenuLinks">
            <a href="/#howitworks" onClick={() => setOpen(false)}>
              <span>How it works</span>
            </a>
            <NavLink to={FAQ}>
              <span> FAQ </span>
            </NavLink>
            <NavLink to={CONTACT}>
              <span>Get in touch</span>
            </NavLink>
            <NavLink to="/signin">
              <span className="lgin"> Log In </span>
            </NavLink>
            <AuthedButton onClick={getStartedFunc} className="Btn--Primary actionGroupBtn">
              <span>Get Started</span>
            </AuthedButton>
          </div>
          <div className="mobileMenuFooter">
            <div className="mobileMenuFooter__social">
              <div className="socialContainer">
                <Insta />
              </div>
              <div className="socialContainer">
                <Face />
              </div>
            </div>
            <p>Copyright &copy; {new Date().getFullYear()} SameDayCustom Inc. All rights reserved</p>
          </div>
        </div>
      </div>

      <div className="TSCconent">
        <h1>SAMEDAYCUSTOM, LLC</h1>
        <h2>PRINT PROVIDER PARTNER TERMS AND CONDITIONS</h2>
        <p>
          SameDayCustom, LLC and its subsidiaries, entities and affiliates (“SameDayCustom”) owns
          and operates certain websites and website platforms (including program interfaces, apps,
          technology and programs and all associated services (collectively, “Platform”).
          SameDayCustom is a virtual marketplace network that will utilize the Platform to connect
          customers (each a “Customer”, collectively “Customers”) placing orders through the
          Platform for custom print projects (each a “Customer Order”, collectively “Customer
          Orders”) to our approved local print providers (each a “Print Provider”, collectively
          “Print Providers”).
        </p>
        <p>
          These Print Provider Partner Terms and Conditions (“Terms and Conditions”), together with
          the SameDayCustom Print Provider Initiation Agreement (“Agreement”), constitute a fully
          binding and fully enforceable contract between you and SameDayCustom that governs your
          partnership with us as a Print Provider (including but not limited to your access and use
          of both the Platform, your conduct as a Print Provider, and your fulfilment of Customer
          Orders).
          <span className="boldText">
            {" "}
            By providing your electronic signature to the Agreement, you unconditionally agree to
            all terms and conditions contained in these Terms and Conditions (including additional
            terms and conditions that are incorporated by reference in these Terms and Conditions)
            and all corresponding amendments to these Terms and Conditions, as permitted herein.{" "}
          </span>
        </p>

        <h4>
          {" "}
          I <span className="tcSectionHeading"> EFFECTIVENESS AND TERMINATION </span>
        </h4>

        <p>
          These Terms & Conditions will become effective on your completion of the Agreement, and it
          will remain effective throughout your participation as a Print Provider, unless otherwise
          terminated by us as permitted herein. SpecificaNothinlly, we may terminate these Terms and
          Conditions and your further participation hereunder, in whole or in part, for cause or
          convenience, immediately upon written notice to you. Additionally, your rights under these
          Terms and Conditions (including but not limited to your right to receive compensation)
          will terminate automatically without notice if you fail to comply with any of the terms of
          these Terms and Conditions. Further, we reserve the right, in our sole and absolute
          discretion, for cause or convenience, to modify, suspend, or discontinue at any time, with
          or without notice, the Platform, your Account (as defined herein) and/or your ability to
          participate on the Platform.
        </p>
        <p>
          Upon termination of these Terms and Conditions for cause or convenience: (i) your access
          rights to the Platform will terminate; (ii) you must immediately cease all use of the
          Platform; (iii) you must immediately take down any and all references to SameDayCustom and
          the Platform; and (iv) you will forfeit any pending, current, or future Customer orders
          (each a “Customer Order”, collectively “Customer Orders”) (whether or not we have already
          connected you with such Customer) without notice to you. Additionally, and to the extent
          deemed necessary by us (in our sole discretion) in the case of a termination for cause (or
          suspension, discontinuation, etc.), we reserve the right to withhold payment for any and
          all orders related to the cause resulting in the termination (or the suspension,
          discontinuation, etc.). All provisions of these Terms and Conditions that contemplate or
          govern performance or observation subsequent to termination will survive the termination.
        </p>
        <h4>
          {" "}
          II <span className="tcSectionHeading"> AMENDMENTS TO THESE TERMS AND CONDITIONS </span>
        </h4>
        <p>
          These Terms and Conditions (and/or any of the terms and conditions herein) may be amended
          from time to time, by us in our sole discretion, and will supersede all previous versions
          of these Terms and Conditions (each an “Update”, collectively, “Updates”). These Terms and
          Conditions will be posted on
          <NavLink to={TCS} className="tscLink">
            https://partner.samedaycustom.com/terms-and-conditions
          </NavLink>
          You are fully responsible for checking for Updates to the Terms and Conditions and you
          understand, acknowledge and agree that we are under no obligation to either notify you of
          Updates or send you an updated Terms and Conditions (regardless of whether the Updates are
          deemed material or not). By continuing to participate with us, you agree to any and all
          Updates.
        </p>
        <h4>
          {" "}
          III <span className="tcSectionHeading"> YOUR COMMISSION AND TAXES </span>
        </h4>
        <p>
          Provided you are in compliance with all terms and conditions of these Terms and
          Conditions, and Customer provides confirmation of satisfaction (in accordance with Section
          V Customer Satisfaction) you will receive compensation in accordance with Financial Terms
          section of the Agreement. We may offer payment discounts and/or other compensation
          incentives, in our sole discretion.
        </p>
        <p>
          You are responsible for and shall pay all federal, state, local and/or foreign taxes,
          fees, premiums, or surcharged measured or based, in whole or in part, on the net income,
          net profit, gross income, gross profit, or gross receipts applicable to amounts you
          receive under these Terms and Conditions and all excise, privilege, net worth, capital,
          franchise, value added, occupational, or any other taxes applicable to the amounts you
          receive under these Terms and Conditions.
        </p>
        <h4>
          {" "}
          IV{" "}
          <span className="tcSectionHeading"> PARTNERSHIP PROCESS AND YOUR RESPONSIBILITIES </span>
        </h4>
        <p>
          We will utilize our Platform to connect Customers to you, pursuant to the below
          partnership process (“Partnership Process”), in order for you to fulfill Customer Orders
          and produce the Customers’ goods (collectively referred to herein as “Goods”). You
          understand and agree that you will not brand (or otherwise include any of your branding)
          on the Goods that you fulfill and produce. All Goods shall be branded as SameDayCustom.
        </p>
        <p>
          For ease of convenience, we have included the details of the Partnership Process below.
          You understand and acknowledge that all decisions regarding the Partnership Process are to
          be made by us in our sole discretion and such decisions are final and binding.
        </p>
        <div className="tcsIndent">
          <ol>
            <li>
              <span className="listItemHeader"> Platform Access: </span>
              After we confirm you as an authorized Print Partner, you will be provided access to
              the Print Provider Platform (“Print Provider Platform”) for the sole purpose of
              obtaining and fulfilling Customers’ orders and providing corresponding Customer Goods.
              Your right and ability to continue using the Print Provider Platform is contingent
              upon your full and complete compliance with these Terms and Conditions and will
              terminate immediately if these Terms and Conditions is terminated.
            </li>
            <li>
              <span className="listItemHeader"> Account Maintenance: </span>
              You are responsible for establishing, setting up and maintaining your Print Provider
              Platform account (“Account”) and the confidentiality and security of your Account
              (including but not limited to any Customer data and/or information that may be
              transmitted to you via your Account or through any other means). You agree to: (i)
              provide true, accurate, current, and complete information on your Account; and (ii)
              maintain and promptly update the information you provide to ensure that it remains
              true, accurate, current, and complete.
            </li>
            <li>
              <span className="listItemHeader"> Order Transmission: </span>
              We will transmit Customer orders (including certain order information) to you via your
              Account. Order information transmitted to you may include Names, Phone numbers,
              Emails, Shipping addresses, Billing Addresses, Delivery addresses, Customers Private
              Designs & Images. You are responsible for checking your Account and timely, properly,
              and completely fulfilling Customer orders transmitted to you (including but not
              limited to accepting or declining new orders, producing and fulfilling the Goods
              requested in such order, cancelling orders, reviewing and accepting artwork, marking
              orders as produced, recording a delivery, and communicating questions/issues to us).
              You understand and acknowledge that we have the right but not the obligation to
              connect Customers to you, and we have the right (in our sole discretion) all aspects
              of transmitting Customer Orders to Print Providers (including but not limited to, what
              Print Providers, timing on transmission, preference in transmission, parameters
              surrounding our transmission, etc.).
            </li>
            <li>
              <span className="listItemHeader"> Review of Customer Order: </span>
              You are responsible for reviewing all Customer Orders transmitted to your Account.
              This responsibility includes but is not limited to confirming the Customer Order (and
              any and all elements thereof): (i) does not directly or indirectly, infringe any
              intellectual property rights of any third parties, including without limitation
              trademarks, trade secrets, trade dress, design, mask work, copyrights or patent
              rights; (ii) does not subject us to ridicule, embarrassment, scandal or controversy or
              negatively affect our Platform or Service; and/or (iii) is not pornographic,
              offensive, defamatory or otherwise illegal or controversial. You understand and
              acknowledge that we are not responsible in any way to review and/or confirm Customer
              Orders or any elements thereof.
            </li>
            <li>
              <span className="listItemHeader"> Fulfilment of Customer Order: </span>
              You are responsible for properly, completely, correctly, safely and timely fulfilling
              all Customer Orders transmitted to your Account. Additionally, you are responsible for
              communicating any questions, concerns, or issues with Orders to the customer using our
              communication tools. You understand and acknowledge that we are not responsible in any
              way to fulfil any Customer Orders or any elements thereof.
              <div className="tcsIndent">
                <ul>
                  <li>
                    <span className="listItemHeader"> Packaging Note: </span>
                    You acknowledge and agree that you will fulfil Customer Orders packaging
                    provided by us (as opposed to your own branded packaging). We will provide you
                    SameDayCustom-branded packing (either a bag or a box) for you to use packaging
                    and fulfilling Customer Order.
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <span className="listItemHeader"> Shipment and Delivery of Order: </span>
              You are responsible for all aspects of shipment and delivery of all Customer Orders
              and corresponding Goods to Customers (including but not limited to following the
              Platform steps, printing all documentation needed, utilizing appropriate packaging and
              safety precautions, shipment, status tracking, working with third-party logistics
              providers, uploading pickup confirmation into our system, lost or damaged Goods,
              notifying us order is complete). You understand and acknowledge that we are not
              responsible in any way for the shipment or delivery of any Customer Order (or
              corresponding Goods).
              <div className="tcsIndent">
                <ul>
                  <li>
                    <span className="listItemHeader"> Delivery Issues Note: </span>
                    You acknowledge and agree that you are solely responsible for all communications
                    necessary with any and all third-party logistics providers regarding the
                    fulfilment and/or shipment of Goods (including but not limited to issues
                    regarding lost or damaged Goods).
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <span className="listItemHeader"> Uploading of Pickup Confirmation Slip: </span>
              The pickup confirmation slip (“Pickup Confirmation Slip”) is to be printed and
              completed by the Customer. You are responsible for all aspects of the Pickup
              Confirmation Slip (including but not limited to having Customer complete and uploading
              the Pickup Confirmation Slip into the Platform after compilation). To the extent the
              Pickup Confirmation Slip is not uploaded for any order, you understand and acknowledge
              that we may withhold any and all payment (including your commission) in our sole
              discretion.
            </li>
            <li>
              <span className="listItemHeader"> Resolution of Customer Issues: </span>
              We direct Customer to contact you for resolution of all issues (including but not
              limited to questions, complaints, specifications, changes/revisions, etc.) regarding
              Customer Orders transmitted to your Account. We have made available to you a chat
              system to communicate directly with the Customer. You understand and acknowledge that
              we are not responsible in any way for the resolution of any Customer issues related to
              Customer Orders transmitted to your Account.
            </li>
          </ol>
        </div>
        <h4>
          {" "}
          V <span className="tcSectionHeading"> ORDER SATIFACTION </span>
        </h4>
        <p>
          At the conclusion of each Customer Order, and prior to making payment to Print Providers
          for such order, we will seek feedback from the Customer. If the Customer provides
          confirmation of satisfaction with the Customer Order, we will initiate the payment process
          pursuant to the Financial Terms section of the Agreement. If the Customer does not provide
          confirmation of satisfaction with the Customer Order, or otherwise identifies any issues
          with the Customer Order, we will direct the Customer to contact the Print Provider at
          issue to seek a resolution for the Customer Order. If Print Provider resolves the issue to
          Customer’s satisfaction, we will initiate the payment process pursuant to Financial Terms
          section of the Agreement. If Print Provider is unable to resolve the issue to Customer’s
          satisfaction, we will initiate a refund to Customer and Print Provider will not receive
          payment for the Customer Order. All decisions made under this section will be made by us,
          in our sole discretion. All of our decisions are final and binding.
        </p>
        <h4>
          {" "}
          VI <span className="tcSectionHeading"> Right to Reassign Orders </span>
        </h4>
        <p>
          SameDayCustom reserves the right to reassign any Customer Orders to an alternative Print Provider at its sole discretion, at any time or any stage of the Customer Order lifecycle or fulfillment process. This reassignment may occur for any of the following reasons, including but not limited to:
        </p>
        <div className="tcsIndent">
          <ul>
            <li>Failure to meet SameDayCustom’s quality or fulfillment standards;</li>
            <li>Operational disruptions, such as equipment failure or staffing shortages;</li>
            <li>Lack of operational capabilities or capacity to fulfill the order;</li>
            <li>Failure to respond or accept the order within the required time frame;</li>
            <li>Consistent delays in processing or fulfilling orders;</li>
            <li>Any non-compliance with SameDayCustom’s policies, procedures, or terms;</li>
            <li>Any other reason SameDayCustom deems necessary to ensure timely and satisfactory completion of Customer Orders.</li>
          </ul>
        </div>
        <p>Such reassignments shall not constitute a breach of this Agreement, and the Print Provider or any other party shall not be entitled to compensation or claims arising from such reassignments.</p>
        <h4>
          {" "}
          VII <span className="tcSectionHeading"> REPRESENTATIONS AND WARRANTIES </span>
        </h4>
        <p>
          You represent and warrant that: (i) you have the sole right, power and authority to enter
          into these Terms and Conditions and to perform your obligations hereunder; (ii) the
          execution, delivery, and performance of these Terms and Conditions shall not conflict with
          your charter or bylaws, or any agreement, order, or judgment to which you are bound; (iii)
          there are no actions, suits, proceedings or regulatory investigations pending or
          threatened against or affecting that party before any court or administrative; (iv) the
          Goods do not and shall not directly or indirectly, infringe any intellectual property
          rights of any third parties, including without limitation trademarks, trade secrets, trade
          dress, design, mask work, copyrights or patent rights; (v) the Goods will be provided by
          you in a safe, professional, timely, and workmanlike manner consistent with prevailing
          industry standards (including but not limited to all U.S. product safety and product
          testing requirements) and in accordance with the requirements and specifications set forth
          by the Customers; (vi) all equipment and resources you use to provide the Goods are in
          good working order, secure, virus-free and properly protected from security threats, in
          accordance with no less than industry standard requirements; and (vii) you are and shall
          at all times comply with all applicable local, state, federal, provincial, and
          international laws, regulations, rules, and ordinances in connection with these Terms and
          Conditions, the Platform, and all services performed and/or Goods hereunder.
        </p>
        <h4>
          {" "}
          VIII <span className="tcSectionHeading"> CONFIDENTIALITY </span>
        </h4>
        <p>
          All non-public, confidential, and/or proprietary information, including without limitation
          specifications, samples, designs, plans, data, Customer lists, Customer information,
          pricing, discounts, financial information, marketing plans, business plans, documents, and
          operational information (“Confidential Information”) disclosed by us to you, whether
          disclosed orally or disclosed or accessed in written, electronic or other form or media,
          and whether or not marked, designated, or otherwise identified as confidential, in
          connection with these Terms and Conditions is (i) confidential and proprietary, and (ii)
          for use by you solely in its performance of your role as a Print Provider pursuant to
          these Terms and Conditions. You shall not disclose any Confidential Information to any
          third party except as authorized by us in writing or as required by law. With the
          exception of disclosing same as required by law, you shall only disclose Confidential
          Information to those employees, agents, managers, counsel, and representatives
          (collectively, “Representatives”) whose duties justify their need to know such information
          in order to perform the services and then only on the basis of a clear understanding by
          such Representatives of their obligation to maintain the confidentiality of such
          Confidential Information and to restrict the access to or use of such Confidential
          Information as provided herein. You shall not disclose any of our Confidential
          Information, or any of the terms of these Terms and Conditions, to any third parties. Your
          security measures utilized to protect Confidential Information, shall, at a minimum,
          employ and maintain, consistent with industry standards, (i) effective logical access
          control and network security control measures, (ii) access-controlled, secured, and
          monitored servers, databases, and other hardware and/or software components, (iii)
          effective network security control measures, (iv) vulnerability management control
          measures over all systems used to process Confidential Information, and (v) encryption,
          ciphers and key lengths (i.e., current FIPS standard) when processing Confidential
          Information. You agree that any breach or threatened breach of this Section may cause us
          substantial and irreparable harm, the extent of which is difficult to ascertain and the
          remedy at law for which may be inadequate. Therefore, in addition to remedies at law or
          equity that may be available, we shall have the right to seek and obtain specific
          performance or other injunctive and equitable relief (including a reduction in payment, as
          determined by us in its sole discretion) to prevent any breach or further breach of this
          Section, without the posting of bond or security. You shall be responsible for damages,
          costs, and expenses incurred by us arising out of a material breach of and/or enforcement
          of this Section either by you or by any of your Representatives.
        </p>
        <h4>
          {" "}
          IX <span className="tcSectionHeading"> PROHIBITED CONDUCT </span>
        </h4>
        <p>You unconditionally understand, acknowledge and agree:</p>
        <div className="tcsIndent">
          <ul>
            <li>
              Not to use the Platform for any purpose (commercial or otherwise) that are not
              expressly permitted by these Terms and Conditions;
            </li>
            <li>
              Not to access the Platform (including the Print Provider Platform and/or your Account)
              using a third party’s Account without the express consent of the Account holder and
              not to attempt to impersonate another Print Provider;
            </li>
            <li>
              Not to avoid, bypass, remove, deactivate, impair, or attempt (through any means
              whatsoever) to circumvent any technological measure implemented by us to protect the
              Platform;
            </li>
            <li>
              Not to conduct any scraping, indexing, surveying, data mining, or any other kind of
              systematic retrieval of data or other content from the Platform;
            </li>
            <li>
              Not to harass, threaten, intimidate, annoy, or otherwise irritate any Customers or any
              of our staff (including but not limited to our employees and affiliates);
            </li>
            <li>
              Not to engage in any criminal or tortious activity, including, without limitation,
              fraud, spamming (including, without limitation, by email), sending of viruses or other
              harmful files, copyright infringement, patent infringement, or theft of trade secrets;
            </li>
            <li>
              Not to disrupt, interfere with, or otherwise harm or violate the security of the
              Platform or any portion of the Print Provider program;
            </li>
            <li>
              Not to engage in any activity (including but not limited to completing Customer
              Orders), that in our sole discretion, is damaging to our reputation or that subjects
              us to a controversy, scandal, illegal activity, harassment, or otherwise exposes us to
              negative publicity;
            </li>
            <li>
              Not to take, use, edit, adapt or otherwise hold our Intellectual Property (as defined
              herein) out to be your own;
            </li>
            <li>
              Not to save any Customer information (including but not limited to contact information
              – including but not limited to name, phone number, email address, physical address) or
              Customer Order information;
            </li>
            <li>
              Not to ask Customers for any information not specifically provided to you by us and
              not to contact Customer for any reason whatsoever outside of Customer Order; and
            </li>
            <li>
              Unless otherwise agreed to by us, not to communicate directly with Customers
              (including but not limited to via emails, phone calls, or text messages).
            </li>
          </ul>
        </div>
        <h4>
          {" "}
          X <span className="tcSectionHeading"> INTELLECTUAL PROPERTY </span>
        </h4>
        <p>
          All of our intellectual property, including without limitation trademarks, logos, designs,
          any and all screen print materials and designs offered by us, data, technology, mask work,
          service marks, trade names, trade dress, copyrights, know-how, trade-secrets and all
          goodwill associated therewith, together with all modifications, adaptations or
          developments to, or based on (“Intellectual Property”), made either by us, a Customer or
          you in connection with these Terms and Conditions or any Customer Order will be and remain
          the sole and exclusive property of us.
        </p>
        <p>
          We grant you a limited, non-exclusive, non-transferable and fully revocable license to
          access and use the Intellectual Property, solely as permitted by these Terms and
          Conditions and subject to all applicable intellectual property laws. Any other use of the
          Intellectual Property is strictly prohibited without first receiving express written
          approval from us. None of the Intellectual Property may be copied, republished, uploaded,
          posted, transmitted, distributed in any way, and/or modified without our express written
          permission. Nothing contained on the Platform (or in these Terms and Conditions or the
          Agreement) should be interpreted as granting to you any license or right to use any of the
          Intellectual Property without our express written permission. For avoidance of doubt, you
          agree to release, indemnify, and hold us harmless from an against any and all claims,
          demands, damages, actions, or causes of action at law or in equity, together with any and
          all losses, costs, or expenses and reasonable attorneys’ fees in connection therewith or
          related thereto your use of our Intellectual Property, regardless of whether or not we
          have provided you permission to use.
        </p>
        <p>
          You grant to us a limited, non-exclusive, non-transferable license to use your company
          name, logo and other intellectual property for promotional purposes in our advertising and
          on our Platform (and website, apps, etc.).
        </p>
        <h4>
          {" "}
          XI <span className="tcSectionHeading"> GOVERNING LAW AND DISPUTE RESOLUTION </span>
        </h4>
        <p>
          The terms and conditions contained in these Terms and Conditions shall be governed by and
          construed in accordance with the laws of the Commonwealth of Pennsylvania, without regard
          to its conflict of laws principles. You irrevocably and unconditionally consent to the
          exclusive jurisdiction of the state and federal courts located in Allegheny County,
          Pennsylvania and agree to bring any action, litigation, or proceeding only in such court.
          You agree that a final judgment in any such action, litigation, or proceeding is
          conclusive and may be enforced in other jurisdictions by suit on the judgment or in any
          other manner provided by law.
        </p>
        <h4>
          {" "}
          XII <span className="tcSectionHeading"> FORCE MAJEURE </span>
        </h4>
        <p>
          Any delay or failure of either Party to perform its obligations under these Terms and
          Conditions will be excused to the extent such delay or failure was caused directly by an
          event beyond such Party’s reasonable control, without such Party’s fault or negligence and
          that by its nature could not have been foreseen or avoided by such Party, which events
          include natural disasters, embargoes, explosions, riots, wars, acts of terrorism,
          pandemic, national, regional, or local emergency, and/or government action (each a “
          <span className="tcsBold">Force Majeure Event</span>”). The affected Party shall give the
          other Party prompt written notice of a Force Majeure Event and the anticipated duration of
          such Force Majeure Event. The affected Party shall use commercially reasonable efforts to
          end the Force Majeure Event, ensure that the effects of any Force Majeure Event are
          minimized, and resume performance under these Terms and Conditions as soon as possible.
        </p>
        <h4>
          {" "}
          XIII <span className="tcSectionHeading"> RELATIONSHIP OF THE PARTIES </span>
        </h4>
        <p>
          Notwithstanding anything to the contrary herein, you shall, at all times, be an
          independent contractor in all respects in connection with these Terms and Conditions and
          your participation as a Print Provider hereunder and nothing herein shall be deemed to
          create a relationship of agency, joint venture, partnership, or employment. You shall not
          represent yourself as an affiliate or agent of ours or as holding any relationship to us
          other than as an independent contractor. You have no authority and you shall not represent
          that you have authority to assume or incur any obligations of any kind in our name.
        </p>
        <h4>
          {" "}
          XIV{" "}
          <span className="tcSectionHeading"> INDEMNIFICATION AND LIMITATION OF LIABILITY </span>
        </h4>
        <p>
          To the fullest extent permitted by law, you, for yourself, your employees, agents,
          successors and assigns shall defend (at our sole discretion) release, indemnify, and hold
          harmless us from an against any and all claims, demands, damages, actions, or causes of
          action at law or in equity, together with any and all losses, costs, or expenses and
          reasonable attorneys’ fees in connection therewith or related thereto: (i) your use of the
          Platform; (ii) your acts and omissions as a Print Provider; (iii) your breach or alleged
          breach of any of the terms of these Terms and Conditions and/or your representations,
          warranties, covenants, responsibilities, obligations or agreements contained herein; the
          Goods and the manufacturing, processing, development, production, marketing, exhibition
          and/or distribution of the Goods. Furthermore, you hereby unconditionally and irrevocably
          waive any and all rights to bring any claim, action, or proceeding against us in
          connection with your acts or omissions under these Terms and Conditions and your
          participation as a Print Provider.
        </p>
        <p className="tcsCapital">
          IN NO EVENT SHALL WE BE LIABLE FOR CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL,
          EXEMPLARY, PUNITIVE OR ENHANCED DAMAGES, LOST PROFITS OR REVENUES OR DIMINUTION IN VALUE,
          ARISING OUT OF, OR RELATING TO THESE TERMS AND CONDITIONS, REGARDLESS OF (A) WHETHER SUCH
          DAMAGES WERE FORESEEABLE, (B) WHETHER OR NOT YOU WERE ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES AND (C) THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT OR OTHERWISE) UPON WHICH THE
          CLAIM IS BASED.
        </p>
        <h4>
          {" "}
          XV <span className="tcSectionHeading"> DISCLAIMER </span>
        </h4>
        <p className="tcsCapital">
          THE PLATFORM, THE INTELLECTUAL PROPERTY, AND ALL OTHER ELEMENTS WHATSOEVER ARE PROVIDED
          &quot;AS IS&quot; AND “AS AVAILABLE” AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
          IMPLIED, UNLESS OTHERWISE SPECIFIED IN WRITING. TO THE FULLEST EXTENT PERMISSIBLE BY
          APPLICABLE LAW, THE RELEASED PARTIES DISCLAIM, WITH RESPECT TO THE PLATFORM, THE
          INTELLECTUAL PROPERTY AND ALL OTHER ELEMENTS WHATSOEVER, ALL WARRANTIES, EXPRESS OR
          IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. SAMEDAYCUSTOM DOES
          NOT REPRESENT OR WARRANT THAT THE PLATFORM, THE INTELLECTUAL PROPERTY AND/OR THE OTHER
          ELEMENTS WILL BE SECURE, UNINTERRUPTED, AND/OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED,
          AND/OR THAT THE PLATFORM, THE INTELLECTUAL PROPERTY, AND/OR OTHER ELEMENTS WHATSOEVER ARE
          FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS. SAMEDAYCUSTOM DOES NOT WARRANT OR MAKE ANY
          REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE PLATFORM, THE
          INTELLECTUAL PROPERTY, AND/OR ANY OTHER ELEMENTS WHATSOEVER IN TERMS OF THEIR CORRECTNESS,
          ACCURACY, RELIABILITY, TIMELINESS, COMPLETENESS, CURRENTNESS, OR OTHERWISE, INCLUDING,
          WITHOUT LIMITATION, THE SAFETY, QUALITY, AND/OR TIMING OF A CUSTOMER ORDER PLACED ON THE
          PLATFORM, AND/OR GOODS DELIVERED. YOU (AND NOT SAMEDAYCUSTOM) ASSUME THE ENTIRE COST OF
          ALL NECESSARY SERVICING, REPAIR, OR CORRECTION RELATING TO YOUR USE OF THE PLATFORM, THE
          INTELLECTUAL PROPERTY, AND/OR OTHER ELEMENTS WHATSOEVER. APPLICABLE LAW MAY NOT ALLOW THE
          EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT FULLY APPLY TO YOU.
          SAMEDAYCUSTOM SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM
          CAUSES BEYOND SAMEDAYCUSTOM&apos;S REASONABLE CONTROL, INCLUDING, WITHOUT LIMITATION,
          DELAYS AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC
          COMMUNICATIONS. SAMEDAYCUSTOM IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR
          OTHER DAMAGE RESULTING FROM SUCH PROBLEMS. SAMEDAYCUSTOM DOES NOT REPRESENT OR WARRANT
          THAT THE INFORMATION ACCESSIBLE THROUGH THE PLATFORM OR THE SERVICE IS ACCURATE, COMPLETE,
          RELIABLE, CURRENT, OR ERROR-FREE.
        </p>
        <h4>
          {" "}
          XVI <span className="tcSectionHeading"> INSURANCE </span>
        </h4>
        <p>
          You shall at all times maintain insurance coverage with commercial general liability
          coverage at a minimum level as required by the state or other municipality in which you
          operate, for bodily injury, property damage, personal injury, products and completed
          operations, and blanket contractual coverage, including but not limited to the liability
          assumed under the indemnification provisions of these Terms and Conditions.
        </p>
        <h4>
          {" "}
          XVII <span className="tcSectionHeading"> NON-ASSIGNMENT </span>
        </h4>
        <p>
          You are prohibited from assigning or transferring these Terms and Conditions, or your
          rights under these Terms and Conditions, in whole or in part, by operation of law or
          otherwise, without our express prior written consent. We may assign these Terms and
          Conditions, in whole or in part, at any time in our sole discretion, without notice or
          consent.
        </p>
        <h4>
          {" "}
          XVIII <span className="tcSectionHeading"> PRIVACY POLICY </span>
        </h4>
        <p>
          The terms and conditions of the Privacy Policy are incorporated into these Terms and
          Conditions by reference claims, demands, damages, actions, or causes of action at law or
          in equity, together with any and all losses, costs, or expenses and reasonable attorneys’
          fees in connection therewith or related thereto
        </p>
        <h4>
          {" "}
          XIX <span className="tcSectionHeading"> ADDITIONAL LEGAL TERMS </span>
        </h4>
        <div className="tcsIndent">
          <ol>
            <li>
              <span className="tcsBold">Captions.</span>
              Captions, article, and section headings and titles used in these Terms and Conditions
              are for convenience of reference only and shall not be construed in a manner that
              affects construction or interpretation of the terms and conditions of these Terms and
              Conditions.
            </li>
            <li>
              <span className="tcsBold">Negotiated Terms.</span>
              You acknowledge that you have had the opportunity to consult with legal counsel and
              have read, fully understood, and agree to be bound by all terms and conditions of
              these Terms and Conditions. Any rule of construction requiring that any term or
              condition in these Terms and Conditions be construed against the drafting party shall
              not apply.
            </li>
            <li>
              <span className="tcsBold">Successors and Assigns.</span>
              These Terms and Conditions is binding upon and inures to the benefit of the parties
              hereto and their respective successors and permitted assigns.
            </li>
            <li>
              <span className="tcsBold">Waiver.</span>
              Any failure by us, at any time, to enforce or require the strict adherence by you of
              any of the terms and conditions of these Terms and Conditions or the Agreement shall
              not constitute a waiver by us of any such terms or conditions and shall not affect or
              impair such terms and conditions in any way or the right of us at any time to avail
              itself of such remedies as it may have for any such breach or breaches of such terms
              or conditions.
            </li>
            <li>
              <span className="tcsBold">Severability.</span>
              In the event any one or more of the provisions of these Terms and Conditions are
              deemed to be invalid, illegal, or unenforceable in any respect pursuant to a final
              determination of any court of competent jurisdiction or by future legislative action,
              the validity, legality, and enforceability of any of the remaining provisions hereof
              shall not, in any way, be affected or impaired thereby.
            </li>
            <li>
              <span className="tcsBold">Entire Agreement.</span>
              These Terms and Conditions, and the Agreement, constitutes the complete, final, and
              entire agreement between you and us.
            </li>
            <li>
              <span className="tcsBold">Amendments.</span>
              No amendment to or modification of these Terms and Conditions is effective unless it
              is in writing, identified as an amendment to these Terms and Conditions and signed by
              an authorized representative of each party to these Terms and Conditions.
            </li>
          </ol>
        </div>
      </div>
      <div className="Landing__footer">
        <div className="topFooter">
          <div className="footerLinks">
            <NavLink to="/#howitworks">
              <span>How it works</span>
            </NavLink>
            <NavLink to={FAQ} activeClassName="Landing__activeLink">
              <span> FAQ </span>
            </NavLink>
            <NavLink to={CONTACT} activeClassName="Landing__activeLink">
              <span>Get in touch</span>
            </NavLink>
            <NavLink to={TCS} activeClassName="Landing__activeLink">
              <span>Terms of use</span>
            </NavLink>
          </div>
          <div className="socialLinks">
            <a href="https://www.instagram.com/samedaycustom/">
              <div className="socialContainer">
                <Insta />
              </div>
            </a>
            <a href="https://twitter.com/SameDayCustom">
              <div className="socialContainer">
                <Face />
              </div>
            </a>
          </div>
        </div>
        <div className="bottomFooter">
          <Logo />
          <p>Copyright &copy; {new Date().getFullYear()} SameDayCustom Inc. All rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default TCSpage;

export const HOMEPAGE = "/";
export const FAQ = "/faqs";
export const CONTACT = "/contact-us";
export const ABANDONED_CART = "/abandoned";

// [[AUTH]]
export const FORGOT_PASSWORD = "/forgot-password";
export const FORGOT_PASSWORD_RESET = "/reset-password";
export const APPLICATION = "/app";
export const AUTHENTICATION = "/auth";
export const DASHBOARD_MULITPLE_STORE_VENDOR = "/dashboard/mutli-store-vendor/add";
export const DASHBOARD_VERIFICATION = "/dashboard/auth/verify";
export const CREATE_PASSWORD = "/create-password";
export const CONFIRMATION = "/confirmation";
export const CONFIRM_REGISTERATION = "/registration-confirmation";
export const SIGN_IN_PAGE = "/signin";
export const RESEND_CONFIRMATION = "/reconfirm";
export const SIGN_UP_PAGE = "/signup";
export const SIGN_UP_STORE = "";
export const SIGN_UP_CREATE = "/create";
export const SIGN_UP_SERVICE = "/service";
export const SIGN_UP_STOCK = "/stock";
export const SIGN_UP_QUANTITY = "/quantity";
export const SIGN_UP_CREATE_PASSWORD = "/createpassword";
export const SIGN_UP_COMPLETE = "/complete";
export const SUB_SETTINGS_PAGE = "/setting/:page";

// [[APP]]

export const DASHBOARD = "/dashboard";
export const REPORTS = "/reports";
export const WEBSHOP_SALES = "/webshop-sales";
export const SETTINGS_PAGE = "/settings";
export const SETTINGS_STORE_PROFILE = `${SETTINGS_PAGE}/store-settings-and-profile`;
export const SETTINGS_STORE_SETTINGS = `${SETTINGS_PAGE}/store-settings`;
export const SETTINGS_SETUP_WORK_HOURS = `${SETTINGS_PAGE}/workhours`;
export const SETTINGS_DELIVERY_METHODS = `${SETTINGS_PAGE}/delivery`;
export const SETTINGS_PRODUCTION_SPEED = `${SETTINGS_PAGE}/production-speed`;
export const SETTINGS_PRINT_METHOD = `${SETTINGS_PAGE}/printing-method`;
export const SETTINGS_STORE_PRODUCTS = `${SETTINGS_PAGE}/store-products`;
export const CUSTOMERS = "/customers";
export const QUOTES = "/quotes";
export const VIEW_CUSTOMER = `${CUSTOMERS}/view`;

// Operations page
export const OPERATION = "/operation";
export const OPERATION_PRODUCTION_HOUSE = `${OPERATION}/productions`;
export const OPERATION_SHIPPING_DELIVERY = `${OPERATION}/shipping`;
export const OPERATION_ORDERS = `${OPERATION}/orders`;
export const OPERATION_VIEW_ORDERS_RECORD = `${OPERATION_ORDERS}/view/record`;
export const OPERATION_VIEW_SHIPPING = `${OPERATION_SHIPPING_DELIVERY}/view/shipping`;
export const OPERATION_VIEW_SHIPPING_DELIVERY_RECORD = `${OPERATION_VIEW_SHIPPING}/record`;
export const OPERATION_VIEW_PRODUCTION = `${OPERATION_PRODUCTION_HOUSE}/view/production`;
export const OPERATION_VIEW_ORDER = `${OPERATION_ORDERS}/view/order`;
export const OPERATION_VIEW_DELIVERY_RECORDED = `${OPERATION}/view/delivery`;

export const USERS_AND_PERMISSIONS = "/users-and-permissions";
export const SPEED = "/speed";
export const ADD_USER = "/users-and-permissions/add-user";
export const EDIT_USER = "/users-and-permissions/edit-user/:id";
export const ADD_ROLE = "/users-and-permissions/add-role";
export const EDIT_ROLE = "/users-and-permissions/edit-role/:id";
export const USER_PROFILE = "/user-profile";
export const BUSINESS_PROFILE = "/business-profile";
export const MESSAGE_PAGE = "/message";
export const STORE_LOCATION = "/store-locations";
export const ADD_STORE_LOCATION = "/store-locations/add-location";
export const EDIT_STORE_LOCATION = "/store-locations/edit-location";
export const CUSTOMIZE_STORE = "/:id/customize-store";
export const CUSTOMIZE_ABOUT_PAGE = "/:id/customize-about-page";
export const CUSTOMIZE_COUPON = "/:id/customize-coupon";
export const CUSTOMIZE_BILLING = "/:id/payments-billing";
export const CUSTOMIZE_RETURN_POLICY = "/:id/return-policy";
export const MANAGE_PRODUCT = "/manage-product/:locId/:productId/:activeWl";
export const CUSTOMIZE_DELIVERY_AND_SHIPPING = "/:id/customize-delivery-and-shipping";
export const CUSTOMIZE_PRODUCTION_SPEED = "/:id/customize-production-speed";

// welcome screens
export const WELCOME_PAGE = "/welcome";
export const TCS = "/terms-and-conditions";
// export const RESEND_CONFIRMATION = "/resend-confirmation"

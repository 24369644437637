export default {
  width: window?.innerWidth,
  height: window?.innerHeight,
  WEIGHT: {
    ounce: "oz",
    pounds: "lb",
  },
  fileType: {
    doc:
      "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*, docx, xlsx and pptx: application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    image: "image/*",
  },
};

export const DATE_FORMAT = "DD MMM YYYY";

export const MAX_IMAGE_UPLOAD_SIZE = 10000000;
